export const GET_RESTAURANTS ='GET_RESTAURANTS'
export const SET_RESTAURANT_PROP ='SET_RESTAURANT_PROP'
export const GET_REWARDS ='GET_REWARDS'
export const GET_IKENTOO_MENU = 'GET_IKENTOO_MENU'
export const BEFORE_SHOW_TIME_PICKER = 'BEFORE_SHOW_TIME_PICKER'
export const BEFORE_CLOSE_TIME_PICKER = 'BEFORE_CLOSE_TIME_PICKER'
export const GET_IKENTOO_MENUS_FOR_LOCATION = 'GET_IKENTOO_MENUS_FOR_LOCATION'
export const GET_RESTAURANTS_SNOOZED_DATA = 'GET_RESTAURANTS_SNOOZED_DATA'
export const SET_DEFAULT_MENU = 'SET_DEFAULT_MENU'
export const CLEAR_IKENOO_MENU = 'CLEAR_IKENOO_MENU'
