import React from 'react'
import QRCode from 'qrcode-react'
import ValidateButton from '../../components/validateButton'
import { withTranslation } from '../../lib/translate'
import { isDefined, validateProfileData, sprintf } from '../../lib/utils'
import { getConfig } from '../../appConfig'
import { SmallText, Spacer, NormalText } from '../../components/common'

const Scan = ({ __, qr_code, profile, available_balance }) => {
	const valid = validateProfileData(profile).isValid
	const points_value_percentage = isDefined(getConfig().api_config) && isDefined(getConfig().api_config.points_value_percentage) ? getConfig().api_config.points_value_percentage : 5
	return (
		<>
			<div className="loyalty-content scan padded scrollable-y">
				<div>
					<SmallText>{ __('Loyalty Wallet') }</SmallText>
					<div className="signature okx-font-secondary margined-top">{ profile.first_name + ' ' + profile.last_name }</div>
				</div>
				{ isDefined(qr_code) ?
					<div className="qr-holder">
						<QRCode value={ qr_code } size={ 150 } />
					</div>
					:
					<>
						<Spacer/>
						<div className="noQrCode"><h5>{ __('NO QR CODE')}</h5></div>
					</>
				}
				<ValidateButton />
				{ !valid && getConfig().appType.hasEmailValidationEnabled ?
					<div className="verified-content">
						<SmallText color="grey">{ __('You can earn, but not redeem points until your account is verified')}</SmallText>
					</div> : null
				}
				<Spacer size={ 3 } />
				<NormalText><strong>{ __('Loyalty Balance') }</strong></NormalText>
				<Spacer size={ 1 } />
				<SmallText><strong>{ sprintf(__('%s Points'), available_balance) }</strong></SmallText>
				<Spacer size={ 2 } />
				<SmallText>{ __('Earn') + ' ' }<strong>{points_value_percentage} { __('points') }</strong> {__('for every ₤1.00 spent')}</SmallText>
			</div>
		</>
	)
}

export default withTranslation(Scan)
