import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from '../../lib/translate'
import Layout from '../../components/layout'
import Loading from '../../components/spinner'
import Mobiscroll from '../../components/mobiscroll'
import Basket from '../../lib/basket'
import { Title, FieldError, SmallText, Spacer } from '../../components/common'
import { IonButton, IonItem, IonInput } from '@ionic/react'
import { isDefined, checkForDeliveryOption, isObject, isArray } from '../../lib/utils'
import moment from '../../lib/moment'
import { setCommonModal, getRestaurants, getIkentooMenu, getIkentooMenusForLocation, setDeliveryOption } from '../../store/actions'
import './index.css'
import { SHOW_TOAST } from '../../store/constants'

const { SelectOption } = Mobiscroll
const restaurantSelectPlaceholder = 'Select collection location'

class OrderToTable extends Component {
	state = {
		selectedRestaurant: null,
		error: '',
		isLocationAllowed: false,
		tableNumber: null
	}

	componentDidMount () {
		Basket.setOrderType('table')
		const deliveryOption = checkForDeliveryOption(this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(), '/order-to-table')
		if (deliveryOption) {
			this.props.dispatch(getRestaurants())
			this.props.dispatch(setDeliveryOption(deliveryOption))
		}
		if (Basket.getMenu()) {
			this.setState({ selectedIkentooMenu: Basket.getMenu() })
		}
		this.position()
	}

	selectRestaurant = (event, data) => {
		const { restaurants, profile } = this.props
		const selectedRestaurantId = data.getVal()
		this.setState({ selectedRestaurant: selectedRestaurantId, pickTime: null }, () => {
			Basket.reset(profile.cardToken)
			Basket.setRestaurant(restaurants.find(restaurant => restaurant.id === selectedRestaurantId))
			Basket.setCollectionTime(null)
			Basket.setOrderType('table')
		})
	}

	formatDataForSelect = (stores) => {
		const { __ } = this.props
		let arrForSelect = []
		stores.forEach((store) => {
			if (isDefined(store.can_table_order) && isDefined(store.is_published)) {
				if (store.can_table_order && store.is_published) {
					arrForSelect.push({ text: store.name, value: store.id })
				}
			}
		})

		if (!this.state.isLocationAllowed) {
			arrForSelect.sort(function(a, b) {
				return a.text < b.text ? -1 : a.text > b.text ? 1 : 0
			})
		}
		return [{ text: __(restaurantSelectPlaceholder), value: null }, ...arrForSelect]
	}

	chooseMenusForLocation = () => {
		const { __, restaurants, dispatch } = this.props
		const { selectedRestaurant, tableNumber } = this.state
		if (selectedRestaurant && tableNumber) {
			const choosenRestaurant = restaurants.find(restaurant => restaurant.id === selectedRestaurant)
			const businessLocationId = choosenRestaurant.business_location_id
			if ( !isDefined(choosenRestaurant.order_to_table_menu_id) || choosenRestaurant.order_to_table_menu_id === "" ) {
				dispatch({ type: SHOW_TOAST, message: __('Menu not found!'), toastType: 'warning' })
				return
			}
			const menuId = choosenRestaurant.order_to_table_menu_id
			let cutoffTimeRes = moment().unix()
			Basket.setCutoffTime(cutoffTimeRes)
			Basket.setTableNumber(tableNumber)
			Basket.setMenu(menuId)
			dispatch(getIkentooMenusForLocation(businessLocationId, { tableNumber, json_time_selector: 'asap' }))
		} else if (!selectedRestaurant) {
			this.setState({ error: __('Please select location') })
		} else {
			this.setState({ error: __('Please enter table number') })
		}
	}

	position = async () => {
		await navigator.geolocation.getCurrentPosition(
			position => this.setState({
				isLocationAllowed: true
			}),
			err => console.log(err)
		)
	}

	render() {
		const { __, deliveryOption, restaurants } = this.props
		const { error } = this.state
		const stores = restaurants || []
		return (
			<Loading transparent>
				<Layout headerTitle={ __(deliveryOption ? deliveryOption.label : '')} noPadding color="transparent">
					<div className="flex-row-wrapper absolute-content">
						<div className="scrollable-y">
							<Title className="okx-font-secondary">{ deliveryOption ? deliveryOption.label : '' }</Title>
							<SmallText>{ __('Enter your table number and your order will be brought to you as soon as possible. This service is available for in-store use only.')}</SmallText>
							<Spacer size={ 4 }/>
							<label className="select-picker-label okx-font-secondary" htmlFor="demo-non-form">{ __('Location') }</label>
							<SelectOption
								display="center"
								onSet={ (e, a) => this.selectRestaurant(e, a) }
								data={ this.formatDataForSelect(stores) }
								label="Location"
								value={ this.state.selectedRestaurant }
								inputStyle="box"
								placeholder={ __(restaurantSelectPlaceholder) }
								setText={ __('OK') }
								cancelText = { __('Cancel') }
							/>
							<br />
							<label className="time-picker-label okx-font-secondary" htmlFor="demo-non-form">{ __('Table Number') }</label>
							{/* <SelectOption
								display="center"
								onSet={ (e, inst) => this.setPickTime(e, inst, minDT) }
								data={ timePickerOptions }
								label="Location"
								value={ pickTime }
								inputStyle="box"
								placeholder={ __('Select Collection Time') }
								setText={ __('OK') }
								cancelText = { __('Cancel') }
								disabled={this.state.selectedRestaurant === null ? true : false}
								onInit={ () => {
									if (timePickerOptions.length > 0) {
										const firstAvailableTime = timePickerOptions.find(i => i.value !== null)
										if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
											this.changeTime(firstAvailableTime.value, minDT)
										}
									}
								}}
							/> */}
							<IonInput className="table-number-input" onIonChange={(e) => this.setState({ tableNumber: e.target.value })} value={this.state.tableNumber} type="text" inputmode="tel" placeholder="Enter table number" />
							{ error ? <IonItem><FieldError className="field-error" value={ __(error) }/></IonItem> : null}
						</div>
						<div className="flex-min">
							<IonButton disabled={ this.state.tableNumber ? false : true } expand="block" color="primary" className="uppercase okx-font-secondary" onClick={ () => this.chooseMenusForLocation()}>{ __('Continue')}</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { deliveryOption } = state.orders
	const { restaurants } = state.restaurants
	return {
		deliveryOption,
		restaurants: restaurants || [],
		profile: state.profile.profile
	}
}

export default connect(stateToProps)(withTranslation(OrderToTable))
